@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  font-family: "Poppins", sans-serif;
}

.App {
  text-align: left;
}
a {
  text-decoration: none !important;
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.ant-input-outlined {
  /* background: #15112c; */
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
}

.ant-select-selection-item {
  color: #ffffff !important;
}

.ant-picker-dropdown .ant-picker-panel-layout {
  background-color: #15112c !important;
  border: 1px solid #ffffff;
  color: #ffffff !important;
  overflow: hidden;
  border-radius: 10px !important;
}
.ant-picker-footer .ant-picker-now-btn,
.ant-picker-body .ant-picker-content th,
.ant-picker-body .ant-picker-content td,
.ant-picker-header-prev-btn,
.ant-picker-header-next-btn,
.ant-picker-header-super-next-btn,
.ant-picker-header-super-prev-btn,
.ant-picker-header {
  color: #ffffff !important;
}
.ant-select-item:hover {
  background-color: #ffffff12 !important;
}
.ant-dropdown-menu,
.ant-select-dropdown {
  background-color: #15112c !important;
  border: 1px solid #ffffff;
  /* width:  !important; */
  padding: 0.5em 1em;
}
.ant-dropdown-menu {
  padding: 0px !important;
  overflow: hidden;
}
.ant-dropdown-menu a {
  color: #ffffff !important;
}
.ant-dropdown-menu li {
  color: #ffffff !important;
  border-radius: 0 !important;
  border-bottom: 1px solid #ffffff !important;
}
.ant-dropdown-menu li:hover {
  background-color: #88888820 !important;
}
.ant-dropdown-menu li:last-child {
  color: #ffffff !important;
  border-bottom: none !important;
}
.prioritySelect .ant-select-dropdown .ant-select-item {
  background-color: yellow !important;
  border: 1px solid #ffffff;
}
.ant-select-dropdown .ant-select-item {
  color: #ffffff;
}
.ant-select-open svg {
  transform: rotate(-180deg) !important;
}
.medium,
.low,
.high {
  background-color: #ff8888 !important;
  border: 1px solid #ff8888;
  color: #000000 !important;
  text-align: center;
  border-radius: 30px !important;
  margin: 0.4em auto !important;
}
.low {
  background-color: #ffef61 !important;
  border: 1px solid #ffef61;
}
.medium {
  background-color: #8aff88 !important;
  border: 1px solid #8aff88;
  width: 90px;
}
::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f100;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 30px !important;
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.ant-picker-cell-today .ant-picker-cell-inner,
.ant-picker-cell-selected .ant-picker-cell-inner {
  border-radius: 50% !important;
}
.ant-picker-cell-today .ant-picker-cell-inner::before,
.ant-picker-cell-selected .ant-picker-cell-inner::before {
  border-radius: 50% !important;
  border: 1px solid #1677ff;
}
.ant-table-cell .ant-checkbox .ant-checkbox-inner {
  background-color: #15112c !important;
}
.ant-table-cell .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1677ff !important;
}
.ant-table-cell .ant-checkbox .ant-checkbox-inner::after {
  border: 2px solid #15112c !important;
  border-top: 0 !important;
  border-left: 0 !important;
}
.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  color: #000000 !important;
}
.ant-picker-time-panel-cell-inner {
  color: #ffffff !important;
}
:where(
    .css-dev-only-do-not-override-1ae8k9u
  ).ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
  background: transparent !important;
  border-color: #ffffff !important;
}

.ant-select-item-option-content {
  flex: auto;
  align-items: center;
  /* overflow: unset !important; */
  display: flex !important;
  gap: 0.3em;
}
.ant-select-item-option-content svg {
  display: inline-block;
}
::placeholder {
  color: #acacac !important;
  font-family: "Poppins", sans-serif !important;
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
  background-color: #15112c !important;
  /* transition: background-color 0s 600000s, color 0s 600000s !important; */
}
.ant-upload-list-item-actions button svg,
.ant-upload-list-item-name,
.ant-empty-description {
  color: #ffffff !important;
}
.ant-upload-list-item {
  color: #ffffff !important;
  border: 1px solid #d9d9d9 !important;
}
.ant-form-item-explain {
  padding-left: 1em !important;
}
@media only screen and (max-width: 768px) {
  .ant-select-dropdown {
    width: 85% !important;
    left: 7.5% !important;
  }
}
/* .ant-upload-list {
  display: none !important;
} */
.ant-select-item-empty {
  color: #ffffff !important;
  cursor: pointer !important;
}
.rc-virtual-list-scrollbar-thumb {
  background: #888 !important;
}
